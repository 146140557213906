import * as Types from '@haaretz/s-fragments/Types';

export type SelectedOfferFragment = (
  { contentId: string, periodCalc: number, prices: Array<number>, productNumber: number, paymentType: Types.PaymentType, promotionNumber: number, saleCode: number, thankYouMailTemplate: string, offerType: Types.OfferType }
  & { __typename: 'PurchaseOffer' }
);


export type SelectedOfferFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const SelectedOfferFragmentGql = `
    fragment SelectedOfferFragment on PurchaseOffer {
  __typename
  contentId
  periodCalc
  prices
  productNumber
  paymentType
  promotionNumber
  saleCode
  thankYouMailTemplate
  offerType
}
    `;