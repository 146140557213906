'use client';

import React from 'react';

export default function useDocumentEventListener(
  ...args: Parameters<typeof document.addEventListener>
) {
  const [eventName, listener, options] = args;

  React.useEffect(() => {
    document.addEventListener(eventName, listener, options);
    return () => {
      document.removeEventListener(eventName, listener, options);
    };
  }, [eventName, listener, options]);
}
