import config from '@haaretz/l-config';
import usePageType from '@haaretz/s-atoms/pageType';
import { usePathname as useAppPathname } from 'next/navigation';
import { useRouter } from 'next/router';

import type { HtzAppConfig } from '@haaretz/l-app-config/types';
import type { BiPageType } from '@haaretz/s-data-structure-types';
import type { ConnectionType, Env } from '@haaretz/s-types';

const appType: HtzAppConfig<Env, ConnectionType>['appType'] = config.get('appType');

function usePagesPathname() {
  const router = useRouter();

  return router.pathname;
}

export default function useBiPageType(): BiPageType {
  const pageType = usePageType();
  const usePathname = appType === 'promotions-page' ? usePagesPathname : useAppPathname;

  const pathname = usePathname();

  if (appType === 'promotions-page') {
    return 'Promotions';
  }

  if (pathname.includes('ty-search')) {
    return 'Search';
  }

  if (appType === 'login') {
    throw new Error('Login case not implemented yet');
  }

  if (pageType.endsWith('Article')) {
    return 'Article';
  }

  if (pageType === 'Homepage') {
    return pageType;
  }

  return 'Section';
}
