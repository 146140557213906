import * as Types from '@haaretz/s-fragments/Types';

import { PageDataFragmentGql } from '@haaretz/s-fragments/PageData';
import { StepDataFragmentGql } from '@haaretz/s-fragments/StepData';
import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { PurchaseToggleKickerFragmentGql } from '@haaretz/s-fragments/PurchaseToggleKicker';
import { PurchaseOfferFragmentGql } from '@haaretz/s-fragments/PurchaseOffer';
import { MarketingBulletFragmentGql } from '@haaretz/s-fragments/MarketingBullet';
import { SpecialSaleDataFragmentGql } from '@haaretz/s-fragments/SpecialSaleData';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { CartDataFragmentGql } from '@haaretz/s-fragments/CartData';
import { SelectedOfferFragmentGql } from '@haaretz/s-fragments/SelectedOffer';
import { AbTestDataFragmentGql } from '@haaretz/s-fragments/AbTestData';
import { PageDataFragment } from '@haaretz/s-fragments/PageData';
import { StepDataFragment } from '@haaretz/s-fragments/StepData';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { PurchaseToggleKickerFragment } from '@haaretz/s-fragments/PurchaseToggleKicker';
import { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';
import { MarketingBulletFragment } from '@haaretz/s-fragments/MarketingBullet';
import { SpecialSaleDataFragment } from '@haaretz/s-fragments/SpecialSaleData';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
import { CartDataFragment } from '@haaretz/s-fragments/CartData';
import { SelectedOfferFragment } from '@haaretz/s-fragments/SelectedOffer';
import { AbTestDataFragment } from '@haaretz/s-fragments/AbTestData';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type PurchasePagePersonalQueryVariables = Types.Exact<{
  couponId?: Types.InputMaybe<Types.Scalars['String']>;
  pathSegment?: Types.InputMaybe<Types.Scalars['String']>;
  selectedOfferId?: Types.InputMaybe<Types.Scalars['String']>;
  ticketId?: Types.InputMaybe<Types.Scalars['String']>;
  userId?: Types.InputMaybe<Types.Scalars['String']>;
  cartData?: Types.InputMaybe<Types.Scalars['Boolean']>;
  previousSteps?: Types.InputMaybe<Types.Scalars['String']>;
  flow?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PurchasePagePersonalQuery = (
  { PurchasePage?: (
    { pageData: (
      PageDataFragment
      & { __typename: 'PageData' }
    ) }
    & { __typename: 'PurchasePage' }
  ) | null }
  & { __typename: 'Query' }
);


export const PurchasePagePersonalQueryDocument = `
    query PurchasePagePersonalQuery($couponId: String, $pathSegment: String, $selectedOfferId: String, $ticketId: String, $userId: String, $cartData: Boolean, $previousSteps: String, $flow: String) {
  PurchasePage(
    couponId: $couponId
    pathSegment: $pathSegment
    selectedOfferId: $selectedOfferId
    ticketId: $ticketId
    userId: $userId
    cartData: $cartData
    previousSteps: $previousSteps
    flow: $flow
  ) {
    pageData {
      ...PageDataFragment
    }
  }
}
    ${PageDataFragmentGql}
${StepDataFragmentGql}
${HtmlNodeFragmentGql}
${PurchaseToggleKickerFragmentGql}
${PurchaseOfferFragmentGql}
${MarketingBulletFragmentGql}
${SpecialSaleDataFragmentGql}
${ImageFragmentGql}
${ImageCropFragmentGql}
${CartDataFragmentGql}
${SelectedOfferFragmentGql}
${AbTestDataFragmentGql}`;
export const usePurchasePagePersonalQuery = <
      TData = PurchasePagePersonalQuery,
      TError = Error
    >(
      variables?: PurchasePagePersonalQueryVariables,
      options?: UseQueryOptions<PurchasePagePersonalQuery, TError, TData>
    ) =>
    useQuery<PurchasePagePersonalQuery, TError, TData>(
      variables === undefined ? ['PurchasePagePersonalQuery'] : ['PurchasePagePersonalQuery', variables],
      fetch<PurchasePagePersonalQuery, PurchasePagePersonalQueryVariables>(PurchasePagePersonalQueryDocument, variables),
      options
    );
usePurchasePagePersonalQuery.document = PurchasePagePersonalQueryDocument;


usePurchasePagePersonalQuery.getKey = (variables?: PurchasePagePersonalQueryVariables) => variables === undefined ? ['PurchasePagePersonalQuery'] : ['PurchasePagePersonalQuery', variables];
;

export const useInfinitePurchasePagePersonalQuery = <
      TData = PurchasePagePersonalQuery,
      TError = Error
    >(
      pageParamKey: keyof PurchasePagePersonalQueryVariables,
      variables?: PurchasePagePersonalQueryVariables,
      options?: UseInfiniteQueryOptions<PurchasePagePersonalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PurchasePagePersonalQuery, TError, TData>(
      variables === undefined ? ['PurchasePagePersonalQuery.infinite'] : ['PurchasePagePersonalQuery.infinite', variables],
      (metaData) => fetch<PurchasePagePersonalQuery, PurchasePagePersonalQueryVariables>(PurchasePagePersonalQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfinitePurchasePagePersonalQuery.getKey = (variables?: PurchasePagePersonalQueryVariables) => variables === undefined ? ['PurchasePagePersonalQuery.infinite'] : ['PurchasePagePersonalQuery.infinite', variables];
;

usePurchasePagePersonalQuery.fetcher = (variables?: PurchasePagePersonalQueryVariables, options?: RequestInit['headers']) => fetch<PurchasePagePersonalQuery, PurchasePagePersonalQueryVariables>(PurchasePagePersonalQueryDocument, variables, options);