import * as Types from '@haaretz/s-fragments/Types';

export type CartDataFragment = (
  { approveDebtClaim: boolean, contentId: string, periodCalc: number, prices: Array<number>, productNumber: number, promotionNumber: number, saleCode: number, thankYouMailTemplate: string, paymentType: Types.PaymentType }
  & { __typename: 'CartData' }
);


export type CartDataFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const CartDataFragmentGql = `
    fragment CartDataFragment on CartData {
  approveDebtClaim
  contentId
  periodCalc
  prices
  productNumber
  promotionNumber
  saleCode
  thankYouMailTemplate
  paymentType
  __typename
}
    `;