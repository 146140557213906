import useUser from '@haaretz/s-atoms/user';
import discardFields from '@haaretz/s-common-utils/discardFields';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import isAbandonedCartRoute from '@haaretz/s-pp-routing-utils/isAbandonedCartRoute';
import { usePPDataStore, usePPCouponStore } from '@haaretz/s-use-pp-data-store';
import { usePPOffsetStepsStore } from '@haaretz/s-use-pp-offset-steps-store';
import { useRouter } from 'next/router';
import React from 'react';

import type { PurchasePagePersonalQueryVariables } from '@haaretz/s-pp-queries/PurchasePage';
import type { MiddlewareParams } from '@haaretz/s-types';
import type { ParsedUrlQuery } from 'querystring';

type OmittedFields = keyof Pick<PurchasePagePersonalQueryVariables, 'ticketId' | 'userId'>;
export type ppQueryVariables = Omit<PurchasePagePersonalQueryVariables, OmittedFields>;

function getVariablesFromQuery(
  query: ParsedUrlQuery,
  isLoggedIn: boolean
): Pick<
  ppQueryVariables,
  'cartData' | 'pathSegment' | 'previousSteps' | 'selectedOfferId' | 'flow'
> {
  const pathSegment = query.pathSegment as string | undefined;
  const cartData = isLoggedIn && isAbandonedCartRoute(query);
  const previousSteps = '';
  const selectedOfferId = query.selectedOfferId as string | undefined;
  const flow = query.flow as string | undefined;

  return {
    pathSegment,
    cartData,
    previousSteps,
    flow,
    ...(selectedOfferId ? { selectedOfferId } : {}),
  };
}

function usePpDataVariables<T extends (keyof Required<ppQueryVariables>)[] = []>(
  excludeValues?: T
) {
  const router = useRouter();

  const { contentId } = usePPDataStore();
  const couponId = usePPCouponStore();
  const offsetSteps = usePPOffsetStepsStore();
  const userData = useUser('cookieValue');

  const previousSteps = offsetSteps.join(',');

  const variableFromQuery = React.useMemo(
    () => getVariablesFromQuery(router.query, userData.isLoggedIn || false),
    [router.query, userData?.isLoggedIn]
  );

  const result = React.useMemo(
    () =>
      discardNullableValues(
        discardFields(
          {
            selectedOfferId: userData?.isLoggedIn ? contentId : undefined,
            ...variableFromQuery,
            couponId,
            previousSteps: variableFromQuery.previousSteps || previousSteps,
          },
          (excludeValues || []) as undefined extends T ? (keyof Required<ppQueryVariables>)[] : T
        )
      ),
    [contentId, couponId, excludeValues, previousSteps, userData?.isLoggedIn, variableFromQuery]
  );

  return result;
}

export function getPpDataVariableFromContext(
  ctx: MiddlewareParams['ctx'],
  isLoggedIn: boolean
): ppQueryVariables {
  return discardNullableValues(getVariablesFromQuery(ctx.query, isLoggedIn));
}

export default usePpDataVariables;
