import * as Types from '@haaretz/s-fragments/Types';

import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
export type PurchaseOfferFragment = (
  { benefitsHeading?: string | null, contentId: string, description: string, exclusive?: string | null, exclusiveStrip?: string | null, note?: string | null, offerHighlight?: string | null, offerType: Types.OfferType, paymentType: Types.PaymentType, periodCalc: number, prices: Array<number>, product: Types.ProductType, productName: string, productNumber: number, promotionNumber: number, saleCode: number, thankYouMailTemplate: string, benefits?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null, legalText: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )>, line1Text: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )>, line2Text?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null }
  & { __typename: 'PurchaseOffer' }
);


export type PurchaseOfferFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const PurchaseOfferFragmentGql = `
    fragment PurchaseOfferFragment on PurchaseOffer {
  __typename
  benefits {
    ...HtmlNodeFragment
  }
  benefitsHeading
  contentId
  description
  exclusive
  exclusiveStrip
  legalText {
    ...HtmlNodeFragment
  }
  line1Text {
    ...HtmlNodeFragment
  }
  line2Text {
    ...HtmlNodeFragment
  }
  note
  offerHighlight
  offerType
  paymentType
  periodCalc
  prices
  product
  productName
  productNumber
  promotionNumber
  saleCode
  thankYouMailTemplate
}
    `;