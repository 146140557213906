import * as Types from '@haaretz/s-fragments/Types';

import { StepDataFragmentGql } from '@haaretz/s-fragments/StepData';
import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { PurchaseToggleKickerFragmentGql } from '@haaretz/s-fragments/PurchaseToggleKicker';
import { PurchaseOfferFragmentGql } from '@haaretz/s-fragments/PurchaseOffer';
import { MarketingBulletFragmentGql } from '@haaretz/s-fragments/MarketingBullet';
import { SpecialSaleDataFragmentGql } from '@haaretz/s-fragments/SpecialSaleData';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { CartDataFragmentGql } from '@haaretz/s-fragments/CartData';
import { SelectedOfferFragmentGql } from '@haaretz/s-fragments/SelectedOffer';
import { AbTestDataFragmentGql } from '@haaretz/s-fragments/AbTestData';
import { StepDataFragment } from '@haaretz/s-fragments/StepData';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { PurchaseToggleKickerFragment } from '@haaretz/s-fragments/PurchaseToggleKicker';
import { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';
import { MarketingBulletFragment } from '@haaretz/s-fragments/MarketingBullet';
import { SpecialSaleDataFragment } from '@haaretz/s-fragments/SpecialSaleData';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
import { CartDataFragment } from '@haaretz/s-fragments/CartData';
import { SelectedOfferFragment } from '@haaretz/s-fragments/SelectedOffer';
import { AbTestDataFragment } from '@haaretz/s-fragments/AbTestData';
export type PageDataFragment = (
  { contentId: string, couponId?: string | null, couponErrorMessage?: string | null, personalCouponId?: string | null, thankYouPageText?: string | null, log?: string | null, userProducts?: Array<number> | null, userType?: string | null, designVersion?: Types.DesignVersion | null, allSteps: Array<(
    StepDataFragment
    & { __typename: 'StepData' }
  )>, toggleKicker?: (
    PurchaseToggleKickerFragment
    & { __typename: 'PurchaseToggleKicker' }
  ) | null, annual?: Array<(
    PurchaseOfferFragment
    & { __typename: 'PurchaseOffer' }
  )> | null, marketingBullets?: Array<(
    MarketingBulletFragment
    & { __typename: 'MarketingBullet' }
  )> | null, monthly?: Array<(
    PurchaseOfferFragment
    & { __typename: 'PurchaseOffer' }
  )> | null, steps: Array<(
    StepDataFragment
    & { __typename: 'StepData' }
  )>, specialSaleData?: (
    SpecialSaleDataFragment
    & { __typename: 'SpecialSaleData' }
  ) | null, cartData?: (
    CartDataFragment
    & { __typename: 'CartData' }
  ) | null, image?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null, selectedOffer?: (
    SelectedOfferFragment
    & { __typename: 'PurchaseOffer' }
  ) | null, abTest?: (
    AbTestDataFragment
    & { __typename: 'AbTestData' }
  ) | null }
  & { __typename: 'PageData' }
);


export type PageDataFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const PageDataFragmentGql = `
    fragment PageDataFragment on PageData {
  allSteps {
    ...StepDataFragment
  }
  contentId
  couponId
  couponErrorMessage
  toggleKicker {
    ...PurchaseToggleKickerFragment
  }
  annual {
    ...PurchaseOfferFragment
  }
  marketingBullets {
    ...MarketingBulletFragment
  }
  monthly {
    ...PurchaseOfferFragment
  }
  personalCouponId
  steps {
    ...StepDataFragment
  }
  specialSaleData {
    ...SpecialSaleDataFragment
  }
  thankYouPageText
  cartData {
    ...CartDataFragment
  }
  image {
    ...ImageFragment
  }
  selectedOffer {
    ...SelectedOfferFragment
  }
  abTest {
    ...AbTestDataFragment
  }
  log
  userProducts
  userType
  designVersion
}
    `;